import Joi from "joi";

const validationSchemas = {
    createBot: Joi.object({
        _method: Joi.string().optional().valid('put'),
        token: Joi.string().required().min(12).max(128),
    }),
    botSetting: Joi.object({
        _method: Joi.string().optional().valid('put'),
        start_message: Joi.string().required().max(5000),
        contact_request: Joi.optional(),
        contact_request_message: Joi.when('contact_request', { is: true, then: Joi.string().required().max(5000) }),
        force_join: Joi.optional(),
        force_join_message: Joi.when('force_join', { is: true, then: Joi.string().required().max(5000) }),
        channels: Joi.when('force_join', { is: true, then: Joi.array().min(1).max(20) }),
        reply_markup_layout: Joi.number().required(),
    }),
    createCommand: Joi.object({
        _method: Joi.string().optional().valid('put'),
        bot_id: Joi.number().required(),
        title: Joi.string().required().max(60),
        message: Joi.string().required().max(5000),
        message_end: Joi.string().optional().max(5000),
        parent_id: Joi.number().required().label('Parent menu'),
        media_id: Joi.optional(),
        inline_keyboards: Joi.when('type', { is: 'inline', then: Joi.array().min(1).max(20) }),
        questions: Joi.when('type', { is: Joi.valid('quiz', 'form'), then: Joi.array().min(1).max(50) }),
        type: Joi.string().required(),
        status: Joi.string().required(),
    }),
    createBulkMessage: Joi.object({
        bot_id: Joi.number().required(),
        text: Joi.string().required().max(5000),
        media_id: Joi.optional(),
    }),
    commandForm: Joi.object({
        title: Joi.string().required().max(60),
        message: Joi.string().required().max(5000),
        message_end: Joi.string().optional().max(5000),
        parent_id: Joi.number().required().label('Parent menu'),
        media_id: Joi.optional(),
    }),
    inlineType:Joi.object({
        type: Joi.string().required().valid('url', 'message'),
        title: Joi.string().required().max(64),
        url: Joi.when('type', { is: 'url', then: Joi.string().uri().required() }),
        message: Joi.when('type', { is: 'message', then: Joi.string().max(5000).required() }),
        callback_data: Joi.string(),
    }),
    quizType: Joi.object({
        message: Joi.string().required().max(5000),
        options: Joi.array().min(2).max(10),
        correct_option: Joi.number(),
    }),
    formType: Joi.object({
        title: Joi.string().required().max(64),
        rules: Joi.string(),
        message: Joi.string().required().max(5000),
        required: Joi.boolean(),
    }),
    createTicket: Joi.object({
        title: Joi.string().required().max(60),
        message: Joi.string().required().max(5000),
    }),
};

export default validationSchemas;
import {useEffect} from "react";
import {useAuth} from "../providers/AuthProvider";
import {useInitData} from "@vkruglikov/react-telegram-web-app";
import useApi from "../services/useApi";
import PageLoading from "../components/PageLoading";

function TelegramAuthenticateLayout({children}) {

    const {data, fetchData} = useApi()
    const {authenticated, login} = useAuth()
    const [initDataUnsafe, initData] = useInitData()

    useEffect( () => {
        fetchData("/login", "POST", {query: initData})
    }, []);

    useEffect(() => {
        if ( !authenticated && data )
            login(data.user, data.token)
    }, [data]);

    // useEffect( () => {
    //     getItem("_token")
    //         .then(value => {
    //             if (value){
    //                 login(initDataUnsafe.user, value)
    //             }else{
    //                 fetchData("/login", "POST", {query: initData})
    //             }
    //         })
    //         .catch(reason => fetchData("/login", "POST", {query: initData}))
    // }, []);



    // useEffect(() => {
    //     if ( !authenticated && data ) {
    //         logout()
    //         setItem("_token", data.token)
    //             .then(value => console.log(value))
    //             .catch(reason => console.log(reason))
    //         login(data.user, data.token)
    //     }
    // }, [data]);

    // useEffect(() => {
    //     if (errorMessage) {
    //         removeItem("_token")
    //             .then(value => fetchData("/login", "POST", {query: initData}))
    //             .catch(reason => fetchData("/login", "POST", {query: initData}))
    //     }
    // }, [errorMessage]);

    return (
        <>
            {authenticated ? children : <PageLoading />}
        </>
    );
}

export default TelegramAuthenticateLayout;

import React, {useEffect, useMemo, useState} from 'react';
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import useApi from "../../services/useApi";
import validationSchemas from "../../schema/validationSchemas";
import CommandForm from "./CommandForm";
import PageLayout from "../../components/PageLayout";
import {rendition} from "../../services/i18n";
import PageHeaderLayout from "../../components/PageHeaderLayout";
import {useBot} from "../../providers/BotProvider";
import PremiumPage from "../payment/PremiumPage";

const init = {
    bot_id: '',
    title: '',
    message: '',
    message_end: '',
    parent_id: '',
    media_id: '',
    inline_keyboards: [],
    questions: [],
    status: 'active',
    type: '',
}

const CommandCreatePage = () => {

    const { id } = useParams()
    const navigate = useNavigate()

    const apiGet = useApi()
    const apiPost = useApi()
    const apiSelect = useApi()
    const {myBot, setBotId} = useBot()



    const [formData, setFormData] = useState(init)
    const [method, setMethod] = useState("create")
    const [options, setOptions] = useState([])

    const [queryParameters] = useSearchParams()
    let type = queryParameters.get("type")
    let bot_id = queryParameters.get("bot_id")


    useEffect(() => {
        setBotId(bot_id)
        apiSelect.fetchData("/command/options")
        setFormData(prev => ({ ...prev, bot_id: bot_id, type: type }))
    }, []);

    useEffect(() => {
        if (typeof id === 'string' && Number.isInteger(parseInt(id))){
            setMethod("edit")
            apiGet.fetchData(`/command/${id}`)
        }
    }, [id]);

    useEffect(() => {
        const {data} = apiSelect
        if(data) {
            setOptions({
                parents: data.parents,
                media: data.media,
                rules: data.rules,
            })
        }
    }, [apiSelect.data]);

    useEffect(() => {
        if (apiGet.data) {
            const {bot_id, title, message, message_end, parent_id, media_id, inline_keyboards, questions, status, type} = apiGet.data
            const updatedData = { _method: 'put', bot_id, title, message, message_end, parent_id, media_id, inline_keyboards, questions, status, type}
            setFormData(updatedData)
        }
    }, [apiGet.data]);

    useEffect(() => {
        if (apiPost.success) {
            navigate(-1)
        }
    }, [apiPost.success]);


    const onSubmit = () => {
        let url = method === "create"? `/command` : `/command/${id}`
        apiPost.fetchData(url, "POST", formData, validationSchemas.createCommand)
    }

    if (myBot && myBot.can && myBot.can.crete_menu)
        return (<PremiumPage />)


    return (
        <PageLayout loading={id && !formData.type} className="form-wrapper">

            <PageHeaderLayout
                title={rendition("New command")}
                subtitle={rendition("List of Commands, Menu, Quiz, and Forms")}
            />

            <CommandForm
                id={id}
                method={method}
                onSubmit={onSubmit}
                options={options}
                formData={formData}
                setFormData={setFormData}
                loading={apiPost.loading}
                errors={apiPost.errors} />
        </PageLayout>
    );
};

export default CommandCreatePage;
import React, {useEffect} from 'react';
import { NextUIProvider } from '@nextui-org/react';
import useDarkMode from "use-dark-mode";
import { Toaster } from 'react-hot-toast';
import {AuthProvider} from "./providers/AuthProvider";
import {BotProvider} from "./providers/BotProvider";
import i18n from "./i18n";
import {I18nextProvider} from "react-i18next";

export function Providers({ children }) {
    const darkMode = useDarkMode(false);

    useEffect(() => {
        document.documentElement.className = darkMode.value ? 'dark' : 'light';
    }, [darkMode.value]);

    return (
        <AuthProvider>

            <NextUIProvider>
                <I18nextProvider i18n={i18n}>
                    <BotProvider>
                        <Toaster/>
                        <main className={`text-foreground bg-zinc-50 dark:bg-[#000000] min-h-screen`}>
                            {children}
                        </main>
                    </BotProvider>
                </I18nextProvider>
            </NextUIProvider>

        </AuthProvider>

    );
}
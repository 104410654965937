import React from 'react';
import ListBox2Component from "../../components/ListBox2Component";
import PageIndexLayout from "../../components/PageIndexLayout";
import { useNavigate, useSearchParams} from "react-router-dom";
import {ChatIcon} from "../../components/icon/ChatIcon";
import PageLayout from "../../components/PageLayout";
import {rendition} from "../../services/i18n";
import PageHeaderLayout from "../../components/PageHeaderLayout";

const CommandIndexPage = () => {

    const navigate = useNavigate()
    const [queryParameters] = useSearchParams()
    let bot_id = queryParameters.get("bot_id")

    const mappedData = (data) => {
        console.log(data)
        return data?.map(i => ({
            id: i.id,
            title: i.title,
            icon: <ChatIcon className="text-lg" />
        }))
    }

    return (
        <PageLayout className="page-wrapper">
            <PageHeaderLayout
                title={rendition("Bot commands")}
                subtitle={rendition("List of Commands, Menu, Quiz, and Forms")}
            />

            <PageIndexLayout method={`/command?bot_id=${bot_id}`} >
                {({data}) => (
                    <>
                        <ListBox2Component
                            data={mappedData(data)}
                            onAction={(i, index) => navigate(`/command/${i.id}`)} />
                    </>
                )}
            </PageIndexLayout>
        </PageLayout>
    );
};

export default CommandIndexPage;

import ListBoxComponent from "../../components/ListBoxComponent";
import PageIndexLayout from "../../components/PageIndexLayout";
import {Link, useNavigate} from "react-router-dom";
import PageLayout from "../../components/PageLayout";
import React from "react";
import {rendition} from "../../services/i18n";
import {Button} from "@nextui-org/react";
import {PlusIcon} from "@heroicons/react/20/solid";
import {ChatBubbleBottomCenterTextIcon, PencilSquareIcon, UsersIcon} from "@heroicons/react/24/outline";
import {IconWrapper} from "../../components/icon/IconWrapper";
import PageHeaderLayout from "../../components/PageHeaderLayout";

function TicketIndexPage() {

    const navigate = useNavigate()

    const mapData = (data) => {
        return data?.map((i) => ({
            id: i.id,
            title: i.title,
            description: i.title,
            avatar: null,
        }))
    }

    return(
        <PageLayout className="page-wrapper">
            <PageHeaderLayout
                title={rendition("Support")}
                action={<Button onClick={() => navigate('/ticket/create')} size="sm" color="primary" endContent={<PencilSquareIcon className="w-5 h-5" />}>{rendition("Create new ticket")}</Button>}
            />

            <PageIndexLayout method="/ticket" hasSearch={false}>
                {({data}) => data && data.map( i => (
                    <Link className="block rounded-lg bg-default text-default-foreground p-4" to={`/ticket/${i.id}`}>
                        #{i.id} - {i.title}
                    </Link>
                ))}

            </PageIndexLayout>
        </PageLayout>
    )
}

export default TicketIndexPage
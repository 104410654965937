import {
    WebAppProvider,
    MainButton,
    useShowPopup,
    useInitData,
    useHapticFeedback,
    BackButton
} from '@vkruglikov/react-telegram-web-app';
import {useEffect, useState} from "react";
import {
    Table,
    TableHeader,
    TableBody,
    TableColumn,
    TableRow,
    TableCell,
    Code,
    ButtonGroup,
    Button,
    Input,
    Select,
    SelectItem, Textarea,
} from "@nextui-org/react";

import {Modal, ModalContent, ModalHeader, ModalBody, ModalFooter,  useDisclosure} from "@nextui-org/react";

const animals = [
    {label: "Cat", value: "cat", description: "The second most popular pet in the world"},
    {label: "Dog", value: "dog", description: "The most popular pet in the world"},
    {label: "Elephant", value: "elephant", description: "The largest land animal"},
    {label: "Lion", value: "lion", description: "The king of the jungle"},
    {label: "Tiger", value: "tiger", description: "The largest cat species"},
    {label: "Giraffe", value: "giraffe", description: "The tallest land animal"},
    {
        label: "Dolphin",
        value: "dolphin",
        description: "A widely distributed and diverse group of aquatic mammals",
    },
    {label: "Penguin", value: "penguin", description: "A group of aquatic flightless birds"},
    {label: "Zebra", value: "zebra", description: "A several species of African equids"},
    {
        label: "Shark",
        value: "shark",
        description: "A group of elasmobranch fish characterized by a cartilaginous skeleton",
    },
    {
        label: "Whale",
        value: "whale",
        description: "Diverse group of fully aquatic placental marine mammals",
    },
    {label: "Otter", value: "otter", description: "A carnivorous mammal in the subfamily Lutrinae"},
    {label: "Crocodile", value: "crocodile", description: "A large semiaquatic reptile"},
];

export default function Telegram() {
    const showPopup = useShowPopup();
    const [webApp, setWebApp] = useState(null);
    const [initDataUnsafe, initData] = useInitData();
    const [impactOccurred, notificationOccurred, selectionChanged] = useHapticFeedback();
    // useEffect(() => {
    //     if (typeof window !== "undefined") {
    //         if (typeof window.Telegram !== "undefined") {
    //             const app = window.Telegram.WebApp;
    //             if (app) {
    //                 app.ready();
    //                 setWebApp(app);
    //             }
    //         }
    //     }
    //
    //
    //
    // }, []);

    const handleClick = () =>
        showPopup({
            message: 'Hello, I am popup',
        });

    const {isOpen, onOpen, onOpenChange, onClose} = useDisclosure();

    return (
        <WebAppProvider options={{smoothButtonsTransition: true}} >
            <h1>unsafe:</h1>
            <Textarea value={JSON.stringify(initDataUnsafe)} />
            <h1>safe:</h1>
            <Textarea value={JSON.stringify(initData)} />
            <ButtonGroup>
                <Button onClick={() => impactOccurred('heavy')}>heavy</Button>
                <Button onClick={() => notificationOccurred('success')}>success</Button>
            </ButtonGroup>

            <Button onPress={onOpen}>Open Modal</Button>
            <Modal isOpen={isOpen} onOpenChange={onOpenChange} size="full">
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1">Modal Title</ModalHeader>
                            <ModalBody>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                    Nullam pulvinar risus non risus hendrerit venenatis.
                                    Pellentesque sit amet hendrerit risus, sed porttitor quam.
                                </p>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                    Nullam pulvinar risus non risus hendrerit venenatis.
                                    Pellentesque sit amet hendrerit risus, sed porttitor quam.
                                </p>
                                <p>
                                    Magna exercitation reprehenderit magna aute tempor cupidatat consequat elit
                                    dolor adipisicing. Mollit dolor eiusmod sunt ex incididunt cillum quis.
                                    Velit duis sit officia eiusmod Lorem aliqua enim laboris do dolor eiusmod.
                                    Et mollit incididunt nisi consectetur esse laborum eiusmod pariatur
                                    proident Lorem eiusmod et. Culpa deserunt nostrud ad veniam.
                                </p>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="danger" variant="light" onPress={onClose}>
                                    Close
                                </Button>
                                <Button color="primary" onPress={onClose}>
                                    Action
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>

            <div className="flex w-full flex-wrap md:flex-nowrap gap-4">
                <Input
                    isInvalid={true}
                    errorMessage="is not a valid text"
                    type="email"
                    label="Email"
                    description="Enter your email" />

                <Select
                    label="Select an animal"
                    className="max-w-xs"
                >
                    {animals.map((animal) => (
                        <SelectItem key={animal.value} value={animal.value}>
                            {animal.label}
                        </SelectItem>
                    ))}
                </Select>
                <Select
                    label="Favorite Animal"
                    placeholder="Select an animal"
                    className="max-w-xs"
                >
                    {animals.map((animal) => (
                        <SelectItem key={animal.value} value={animal.value}>
                            {animal.label}
                        </SelectItem>
                    ))}
                </Select>
            </div>

            <Table aria-label="Example static collection table">
                <TableHeader>
                    <TableColumn>Id</TableColumn>
                    <TableColumn>Name</TableColumn>
                    <TableColumn>Username</TableColumn>
                </TableHeader>
                <TableBody>
                    <TableRow key="1">
                        <TableCell>{ initDataUnsafe.user.id }</TableCell>
                        <TableCell>{ initDataUnsafe.user.first_name }</TableCell>
                        <TableCell>{ initDataUnsafe.user.username }</TableCell>
                    </TableRow>
                </TableBody>
            </Table>


            { isOpen && <BackButton onClick={onClose} />}
            { isOpen && <MainButton text="SAVE" onClick={onClose} />}
            { !isOpen && <MainButton text="SHOW POPUP" onClick={handleClick} />}
        </WebAppProvider>
    );
}
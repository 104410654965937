import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Layout from "./pages/Layout";
import Telegram from "./pages/Telegram";
import BotIndexPage from "./pages/bot/BotIndexPage";
import BotCreatePage from "./pages/bot/BotCreatePage";
import CommandCreatePage from "./pages/command/CommandCreatePage";
import CommandIndexPage from "./pages/command/CommandIndexPage";
import ChatShowPage from "./pages/chat/ChatShowPage";
import ChatIndexPage from "./pages/chat/ChatIndexPage";
import BotDashboardPage from "./pages/bot/BotDashboardPage";
import TicketIndexPage from "./pages/ticket/TicketIndexPage";
import TicketCreatePage from "./pages/ticket/TicketCreatePage";
import TicketShowPage from "./pages/ticket/TicketShowPage";
import ParticipantIndexPage from "./pages/participant/ParticipantIndexPage";
import ParticipantShowPage from "./pages/participant/ParticipantShowPage";
import BotSettingPage from "./pages/bot/BotSettingPage";
import PremiumPage from "./pages/payment/PremiumPage";
import PaymentPage from "./pages/payment/PaymentPage";
import BulkMessageCreatePage from "./pages/bulkMessage/BulkMessageCreatePage";


function App() {


    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<BotIndexPage />} />
                    <Route path="about" element={<About />} />
                    <Route path="telegram" element={<Telegram />} />

                    <Route path="bot" element={<BotIndexPage />} />
                    <Route path="bot/create" element={<BotCreatePage />} />
                    <Route path="bot/dashboard" element={<BotDashboardPage />} />
                    <Route path="bot/:id" element={<BotCreatePage />} />
                    <Route path="bot/:id/setting" element={<BotSettingPage />} />

                    <Route path="command" element={<CommandIndexPage />} />
                    <Route path="command/create" element={<CommandCreatePage />} />
                    <Route path="command/:id" element={<CommandCreatePage />} />

                    <Route path="chat" element={<ChatIndexPage />} />
                    <Route path="chat/:id" element={<ChatShowPage />} />


                    <Route path="participant" element={<ParticipantIndexPage />} />
                    <Route path="participant/:id" element={<ParticipantShowPage />} />
                    <Route path="bulk-message/create" element={<BulkMessageCreatePage />} />

                    <Route path="ticket" element={<TicketIndexPage />} />
                    <Route path="ticket/create" element={<TicketCreatePage />} />
                    <Route path="ticket/:id" element={<TicketShowPage />} />

                    <Route path="premium" element={<PremiumPage />} />
                    <Route path="payment" element={<PaymentPage />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
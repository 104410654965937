import Input from "../../components/ui/Input";
import {rendition} from "../../services/i18n";

function BotForm({formData, setFormData, errors}) {

    return(
        <>
            <Input
                label={rendition('Token')}
                value={formData.token}
                errorMessage={errors.token}
                onValueChange={(value) => setFormData((pre) => ({...pre, token: value}))}
            />
        </>
    )
}

export default BotForm
import React, {useEffect, useState} from "react";
import {rendition} from "../../services/i18n"
import {useNavigate, useParams} from 'react-router-dom';
import useApi from "../../services/useApi";
import validationSchemas from "../../schema/validationSchemas";
import TelegramButton from "../../components/ui/TelegramButton";
import PageLayout from "../../components/PageLayout";
import BotSettingForm from "./BotSettingForm";
import {useBot} from "../../providers/BotProvider";
import PageHeaderLayout from "../../components/PageHeaderLayout";


function BotSettingPage() {

    const { id } = useParams()
    const navigate = useNavigate()
    const apiGet = useApi()
    const apiPost = useApi()
    const {setBotMounted} = useBot()

    const [formData, setFormData] = useState({})

    useEffect(() => {
        apiGet.fetchData(`/bot/${id}/setting`)
    }, [])

    useEffect(() => {
        if (apiGet.data) {
            const {
                bot,
                start_message,
                contact_request_message,
                force_join_message,
            } = apiGet.data
            const updatedData = {
                _method: 'put',
                start_message,
                contact_request: bot.contact_request,
                contact_request_message,
                force_join: bot.force_join,
                force_join_message,
                channels: (bot.channels && bot.channels.length > 0)? bot.channels : [""],
                reply_markup_layout: bot.reply_markup_layout,
            }
            setFormData(updatedData)
        }
    }, [apiGet.data]);

    const onSubmit = () => {
        apiPost.fetchData(`/bot/${id}/setting`, "POST", formData, validationSchemas.botSetting)
    }

    useEffect(() => {
        apiPost.success && setBotMounted(false)
    }, [apiPost.success]);

    return(
        <PageLayout loading={apiGet.loading} className="form-wrapper">
            <>
                <PageHeaderLayout
                    title={rendition("Settings")}
                />

                <BotSettingForm
                    formData={formData}
                    setFormData={setFormData}
                    errors={apiPost.errors} />


                <TelegramButton
                    text={rendition("update")}
                    onClick={onSubmit}
                    disabled={apiPost.loading}/>
            </>
        </PageLayout>
    )
}

export default BotSettingPage
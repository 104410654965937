import {
    Modal,
    ModalContent,
    ModalBody,
    ModalFooter,
    useDisclosure
} from "@nextui-org/react";
import {StarIcon, XCircleIcon} from "@heroicons/react/24/solid";
import {rendition} from "../../services/i18n";
import {useEffect} from "react";
import {PlusCircleIcon} from "@heroicons/react/24/solid";
import TelegramButton from "../../components/ui/TelegramButton";
import {useNavigate, useSearchParams} from "react-router-dom";
import premiumPage from "./PremiumPage";
import {ArrowTrendingUpIcon, RocketLaunchIcon, WalletIcon} from "@heroicons/react/24/outline";



function Content() {
    return (
        <>
            <div className="text-center p-5">
                <span className="inline-block rounded-full p-2 bg-primary text-white">
                    <StarIcon className="w-9 h-9" />
                </span>
                <h1 className="text-xl font-bold my-2">{rendition("Upgrade to premium")}</h1>
                <p>{rendition("Need to upgrade your plan to access this feature")}</p>
            </div>
            <div className="flex flex-col space-y-4 px-6">
                <div className="flex text-sm gap-3">
                    <ArrowTrendingUpIcon className="w-8 h-8"/>
                    <div>
                        <h3>{rendition("No limits")}</h3>
                        <p className="text-default-foreground">{rendition("Remove limits by subscribing to Premium Package")}</p>
                    </div>
                </div>
                <div className="flex text-sm gap-3">
                    <RocketLaunchIcon className="w-8 h-8"/>
                    <div>
                        <h3>{rendition("All premium features")}</h3>
                        <p className="text-default-foreground">{rendition("By upgrade your plan to premium, you can access all premium features.")}</p>
                    </div>
                </div>
                <div className="flex text-sm gap-3">
                    <WalletIcon className="w-8 h-8"/>
                    <div>
                        <h3>{rendition("Pay with TON")}</h3>
                        <p className="text-default-foreground">{rendition("Payment with Toncoin (cryptocurrency) in one easy step. No credit card required.")}</p>
                    </div>
                </div>
            </div>
        </>
    )
}


const LimitModal = ({setIsPremium}) => {

    const navigate = useNavigate()
    const {isOpen, onOpen} = useDisclosure()
    const [queryParameters] = useSearchParams()
    let bot_id = queryParameters.get("bot_id")

    useEffect(() => {
        onOpen()
    }, []);

    return (
        <Modal
            closeButton={<XCircleIcon />}
            classNames={{
                closeButton: "hover:bg-white/5 active:bg-white/10 inline-block w-10 h-10 m-2",
            }}
            isOpen={isOpen}
            backdrop="blur"
            isDismissable={false}
            onClose={() => setIsPremium(false)}
        >
            <ModalContent>
                {(onClose) => (
                    <>
                        <ModalBody>
                            <Content />
                        </ModalBody>
                        <ModalFooter>
                            <TelegramButton
                                text={rendition("Buy Premium")}
                                onClick={() => navigate(`/premium?bot_id=${bot_id}`)} />
                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>
    )
}

export default LimitModal
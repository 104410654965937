import {useState} from 'react';
import {Button, useDisclosure} from "@nextui-org/react";
import DataForm from "./DataForm";
import ModalWrapper from "./ModalWrapper";
import {ChatIcon} from "../../components/icon/ChatIcon";
import ListBox2Component from "../../components/ListBox2Component";
import {rendition} from "../../services/i18n";
import TelegramButton from "../../components/ui/TelegramButton";
import validator from "../../services/validator";
import validationSchemas from "../../schema/validationSchemas";
import useConfirm from "../../services/useConfirm";
import {EllipsisVerticalIcon, TrashIcon} from "@heroicons/react/24/outline"
import {BackButton} from "@vkruglikov/react-telegram-web-app";

const initTypes = {
    inlineType: {
        type: 'url',
        title: '',
        url: '',
        message: '',
        callback_data: 'call@' + (Math.random() + 1).toString(36).substring(7),
    },
    quizType: {
        message: '',
        options: ['', ''],
        correct_option: parseInt("0"),
    },
    formType: {
        title: '',
        rules: '',
        message: '',
        required: true,
    }
}


const CommandForm = ({id, method, onSubmit, options, formData, setFormData, loading, errors}) => {

    const telegram = process.env.REACT_APP_TELEGRAM_PWA
    const {isOpen, onOpen, onClose, onOpenChange} = useDisclosure();
    const [modalData, setModalData] = useState({})
    const [modalIndex, setModalIndex] = useState(null)
    const [step, setStep] = useState(1)
    const [field, setField] = useState("inline_keyboards")
    const [errs, setErrs] = useState({})
    const {ConfirmModal, confirmMethod, isOpen: confirmIsOpen} = useConfirm()

    const mappedItems = (items) => {
        return items.map((i, index) => (
            {
                id: index,
                title: i.title ?? i.message,
                icon: <EllipsisVerticalIcon className="w-6 h-6" />,
                // number: 50
            }
        ))
    }


    const openModal = () => {
        setModalIndex(null)
        onOpen()
    }

    const onAction = (i, index) => {
        const qu = formData[field]
        setModalIndex(index)
        setModalData(qu[index])
        onOpen()
    }

    return (
        <>
            { step === 1 && <DataForm
                options={options}
                formData={formData}
                setFormData={setFormData}
                errors={Object.assign(errors, errs) } /> }


            { step === 2 && ['form', 'quiz', 'inline'].includes(formData.type) &&
                <>
                    <ListBox2Component
                        onAction={onAction}
                        data={mappedItems(formData[field])} />

                    <ModalWrapper
                        isOpen={isOpen}
                        onClose={onClose}
                        onOpenChange={onOpenChange}
                        modalData={modalData}
                        setModalData={setModalData}
                        modalIndex={modalIndex}
                        setModalIndex={setModalIndex}
                        field={field}
                        setField={setField}
                        options={options}
                        formData={formData}
                        setFormData={setFormData}
                        initTypes={initTypes} />

                    <Button fullWidth onPress={openModal}>
                        {rendition("Add item")}
                    </Button>
                </>
            }

            { method === "edit" && step === 1 &&
                <>
                    <ConfirmModal
                        title={rendition("Delete confirmation")}
                        body={rendition("Are you sure?")} />
                    <Button
                        startContent={<TrashIcon className="w-5 h-5" />}
                        onPress={() => confirmMethod({url: `/command/${id}`})}
                        fullWidth
                        color="danger"
                        variant="flat">
                        {rendition("Delete this command")}
                    </Button>
                </>
            }

            { step === 2 && <Button fullWidth onPress={() => setStep(1)}>
                {rendition("Previous step")}
            </Button> }

            { step === 1 && !confirmIsOpen && ["form", "quiz", "inline"].includes(formData.type) && <TelegramButton
                text={rendition("Next")}
                onClick={ () => {
                    setErrs({})
                    let {errors: er} = validator({
                        title: formData.title,
                        message: formData.message,
                        message_end: formData.message_end,
                        parent_id: formData.parent_id,
                        media_id: formData.media_id
                    }, validationSchemas.commandForm)
                    console.log(er)
                    setErrs(er)
                    if (Object.keys(er).length === 0){
                        setStep(2)
                        if (formData[field].length === 0) openModal()
                    }
                }}
                disabled={loading}/>}

            { !isOpen && !confirmIsOpen && (step === 2 || formData.type === "command") && <TelegramButton
                text={method === "create" ? rendition("Create new command") : rendition("Update")}
                onClick={onSubmit}
                disabled={loading}/>}
        </>
    );
}

export default CommandForm;
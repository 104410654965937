
function PageHeaderLayout({title, subtitle = null, action = null, ...restProps}) {
    return (
        <div {...restProps}>
            <div className="flex justify-between items-center">
                <div>
                    <h1 className="title">{title}</h1>
                    <p className="subtitle">{subtitle}</p>
                </div>
                { action && <div>{action}</div>}
            </div>
        </div>
    )
}

export default PageHeaderLayout;
import PageIndexLayout from "../../components/PageIndexLayout";
import {Link, useNavigate} from "react-router-dom";
import {Button, User} from "@nextui-org/react";
import {rendition} from "../../services/i18n";
import PageLayout from "../../components/PageLayout";
import {PencilSquareIcon} from "@heroicons/react/24/outline";
import {ItemCounter} from "../../components/icon/ItemCounter";
import {useAuth} from "../../providers/AuthProvider";
import PageLoading from "../../components/PageLoading";
import {useBot} from "../../providers/BotProvider";




function GetStarted() {

    const navigate = useNavigate()

    return(
        <div className="flex flex-col space-y-4 text-center">
            <div className="py-2">
                <h3 className="text-2xl font-bold">{rendition("Create your first bot")}</h3>
            </div>

            <Button
                startContent={<PencilSquareIcon className="w-6 h-6" />}
                onClick={() => navigate("/bot/create")}
                variant="solid"
                color="primary"
                size="lg" >{rendition("Get started for free")}</Button>

        </div>
    )
}

function MyBtItems({data}) {

    const navigate = useNavigate()

    return(
        <div className="flex flex-col space-y-4 text-center">
            <div className="py-2">
                <h3 className="text-2xl font-bold">{rendition("My telegram bots")}</h3>
            </div>

            <div className="">
                {data?.map( i => (
                    <Link to={`/bot/dashboard?bot_id=${i.id}`} className="w-full mb-2 bg-background shadow-sm text-foreground flex items-center justify-between rounded-xl gap-2 p-4">
                        <User
                            avatarProps={{size: "md", src: i.profile}}
                            description={`@${i.username}`}
                            name={i.first_name}
                        />
                        <div className="flex flex-col items-end gap-1">
                            <ItemCounter />
                        </div>
                    </Link>
                ))}
            </div>

            <Button
                startContent={<PencilSquareIcon className="w-6 h-6" />}
                onClick={() => navigate("/bot/create")}
                variant="solid"
                color="primary"
                size="lg" >{rendition("Create new bot")}</Button>
        </div>
    )
}

function BotIndexPage() {

    const {authenticated} = useAuth()
    const {botItems, loading, success} = useBot()

    if (!authenticated)
        return <PageLoading />

    return(
        <PageLayout back={false} loading={loading} className="page-wrapper">
            { success && botItems.length === 0
                ? <GetStarted />
                : <MyBtItems data={botItems} /> }
        </PageLayout>
    )
}

export default BotIndexPage
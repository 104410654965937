import React, {useEffect, useState} from 'react';
import TelegramButton from "../../components/ui/TelegramButton";
import {useNavigate, useSearchParams} from "react-router-dom";
import {Input, Spinner, Button, Textarea} from "@nextui-org/react";
import {Square2StackIcon} from "@heroicons/react/24/outline";
import toast from "react-hot-toast";
import PageLayout from "../../components/PageLayout";
import {rendition} from "../../services/i18n";
import PageHeaderLayout from "../../components/PageHeaderLayout";
import useApi from "../../services/useApi";
import QRCode from "react-qr-code";
import {CheckIcon} from "@heroicons/react/24/solid";
import {useBot} from "../../providers/BotProvider";


function SuccessResult({id}) {
    const navigate = useNavigate()

    return (
        <div className="flex flex-col space-y-4 items-center justify-around py-6">
            <div>
                <span className="flex items-center text-center w-14 h-14 rounded-full bg-success">
                    <CheckIcon className="w-8 h-8 inline-block mx-auto" />
                </span>
            </div>

            <h3>{rendition("Success Payment")}</h3>
            <p>{rendition("This bot subscribed to premium")}</p>
            <TelegramButton
                onClick={() => navigate(`/bot/dashboard?bot_id=${id}`)}
                text={rendition("Got To Dashboard")} />
        </div>
    )
}



function ShowInvoice({invoice, copyToClipboard}) {

    return (
        <>
            <PageHeaderLayout title={rendition("Invoice")}/>

            <div>
                {rendition("All specified fields must be filled exactly according to the data specified in the fields below to payment this invoice.")}
            </div>


            <div className="flex flex-col space-y-4">
                <Textarea
                    label={rendition("Address")}
                    description={rendition("TON Blockchain")}
                    endContent={<Square2StackIcon
                        onClick={() => {copyToClipboard(invoice.address)}}
                        className="w-6 h-6 text-default-400 cursor-pointer flex-shrink-0" />}
                    value={invoice.address}
                />
                <Input
                    fullWidth
                    label={rendition("Comment")}
                    description={rendition("In some apps named Memo or Tag")}
                    endContent={<Square2StackIcon
                        onClick={() => {copyToClipboard(invoice.comment)}}
                        className="w-6 h-6 text-default-400 cursor-pointer flex-shrink-0" />}
                    value={invoice.comment}
                />
                <Input
                    fullWidth
                    label={rendition("Amount")}
                    endContent={<Square2StackIcon
                        onClick={() => {copyToClipboard(invoice.amount)}}
                        className="w-6 h-6 text-default-400 cursor-pointer flex-shrink-0" />}
                    value={`${invoice.amount} TON`}
                />
            </div>



            <div className="flex flex-col items-center py-6">
                <QRCode
                    bgColor="#ffffff"
                    value={invoice.address}
                    size={128}
                />

                <p className="flex flex-col  items-center">

                    <a className="my-4" target="_blank" href={`https://app.tonkeeper.com/transfer/${invoice.address}?amount=${invoice.amount * 1000000000}&text=${invoice.comment}`}>

                        <Button
                            fullWidth
                            startContent={<img className="w-4 h-4 rounded inline-block" src="/tonkeeper.png" alt="tonkeeper logo"/>}
                            type="button" >
                            Pay With Tonkeeper
                        </Button>
                    </a>

                    <span className="flex gap-2 pr-4 my-4">
                        <Spinner size="sm"  />
                        {rendition("Awaiting payment")}
                    </span>

                    <div className="text-center text-xs text-default-foreground my-4">
                        {rendition("Please contact support if you shot any trouble")}
                    </div>
                </p>

            </div>
        </>
    )
}

const PaymentPage = () => {

    const {fetchData, success} = useApi(false)
    const walletApi = useApi(false)
    const [queryParameters] = useSearchParams()
    let bot_id = queryParameters.get("bot_id")
    let plane_id = queryParameters.get("plane_id")
    const [count, setCount] = useState(0)
    const {setBotMounted} = useBot()
    const [invoice, setInvoice] = useState({
        price: "",
        amount: "",
        network: "TON",
        coin: "TON",
        comment: "",
        address: "",
    })

    const counter = () => {
        setCount(prevState => (prevState + 1))
    }

    const checkout = () => {
        fetchData("/checkout", "POST", {bot_id, plane_id})
    }

    useEffect(() => {
        checkout()
        setTimeout(counter, 10000)
        walletApi.fetchData(`/invoice?plane_id=${plane_id}`)
    }, [])

    useEffect(() => {
        walletApi.data && setInvoice({
            amount: walletApi.data.amount,
            price: walletApi.data.price,
            network: "TON",
            coin: "TON",
            comment: walletApi.data.comment,
            address: walletApi.data.address,
        })
    }, [walletApi.data])

    useEffect(() => {
        success === false && ( setTimeout(counter, 10000) && checkout() )
    }, [count]);

    useEffect(() => {
        success && setBotMounted(false)
    }, [success]);




    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text)
        toast.success(rendition("Copied to clipboard"))
    }

    return (
        <PageLayout className="page-wrapper">
            { success
                ? <SuccessResult id={bot_id} />
                : <ShowInvoice invoice={invoice} copyToClipboard={copyToClipboard} />}
        </PageLayout>
    )
}

export default PaymentPage
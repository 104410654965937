import React, {useState} from 'react';
import {RadioGroup, ScrollShadow} from "@nextui-org/react";
import TelegramButton from "../../components/ui/TelegramButton";
import {useNavigate, useSearchParams} from "react-router-dom";
import CustomRadio from "../../components/ui/CustomRadio";
import PageLayout from "../../components/PageLayout";
import {rendition} from "../../services/i18n";


function Features({features, ...otherProps}) {

    return (
        <div {...otherProps}>
            { features.map((f, index) => (
                <div key={index} className="flex-col w-full py-1 px-4 my-2">
                    <div className="px-2">
                        <h3 className="font-semibold">{f.title}</h3>
                        <span>{f.description}</span>
                    </div>

                    <div className="flex font-semibold w-full mt-1">
                        <span className="flex justify-between rounded-l-md dark:text-gray-800 w-full px-2 py-1 bg-gray-200">
                            <span>{rendition("Free")}</span>
                            <span>{f.free}</span>
                        </span>
                        <span className="flex justify-between rounded-r-md w-full px-2 py-1 text-white bg-gradient-to-r from-cyan-500 to-blue-500">
                            <span>{rendition("Premium")}</span>
                            <span>{f.premium}</span>
                        </span>
                    </div>
                </div>
            ))}
        </div>
    )
}


const PremiumPage = () => {

    const [queryParameters] = useSearchParams()
    let bot_id = queryParameters.get("bot_id")


    const navigate = useNavigate()
    const [prices, setPrices] = useState({
        1: 19,
        2: 99,
    })

    const [plane, setPlane] = useState("1")

    const price = prices[plane]


    const features = [
        {
            title: rendition("Menu, Submenu and Inline keyboard"),
            description: rendition("Increase limits for creating Menu"),
            free: "50",
            premium: "1,000",
        },
        {
            title: rendition("Quiz and Form"),
            description: rendition("Increase limits for creating Quiz and Form"),
            free: "10",
            premium: "500",
        },
        {
            title: rendition("Subscribes"),
            description: rendition("Increase bot subscribers limit"),
            free: "500",
            premium: "10,000",
        },
        {
            title: rendition("Chat history and reply"),
            description: rendition("See chat history for each user and UNLIMITED reply to any user"),
            free: "No",
            premium: "Yes",
        },
        {
            title: rendition("Send post"),
            description: rendition("Up to 100 message to all subscribes per day"),
            free: "3",
            premium: "100",
        },
        {
            title: rendition("Saved Files"),
            description: rendition("Save up to GB files in your bot"),
            free: "3 GB",
            premium: "300 GB",
        },
        {
            title: rendition("Join first"),
            description: rendition("Subscribers should join first to telegram channel(s) to use bot"),
            free: rendition("1 channel"),
            premium: rendition("10 channels"),
        }
    ]

    return (
        <PageLayout>
            <div className="text-center p-4">
                <h1 className="text-2xl font-bold mb-2">{rendition("Premium Package")}</h1>
                <h3 className="text-sm mb-4">
                    {rendition("Go beyond the limits and unlock dozens of exclusive features by subscribing to Premium Package.")}
                </h3>


                <RadioGroup
                    className="flex-col"
                    value={plane}
                    onValueChange={(value) => setPlane(value)}>
                        <CustomRadio className="text-left" value="1">
                            {rendition("Monthly")}
                            <br/>
                            <span className="font-bold text-2xl">$19 / {rendition("month")}</span>
                        </CustomRadio>
                        <CustomRadio className="text-left" value="2">
                            {rendition("Annually")}
                            <span className="text-green-600 text-sm bg-green-200 rounded-md px-2 ml-1">
                                    {rendition("SAVE")} 60%
                                </span>
                            <br/>
                            <span className="font-bold text-2xl">$99 / {rendition("year")}</span>
                        </CustomRadio>
                </RadioGroup>
            </div>

            <Features className="my-4" features={features} />

            <TelegramButton
                text={rendition("Pay ${{price}} with 💎 Toncoin", {price: price})}
                onClick={() => navigate(`/payment?bot_id=${bot_id}&plane_id=${plane}`)} />
        </PageLayout>
    )
}

export default PremiumPage
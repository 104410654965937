import React, {useEffect, useState} from "react";
import {rendition} from "../../services/i18n"
import {useNavigate} from 'react-router-dom';
import useApi from "../../services/useApi";
import validationSchemas from "../../schema/validationSchemas";
import TelegramButton from "../../components/ui/TelegramButton";
import PageLayout from "../../components/PageLayout";
import TicketForm from "./TicketForm";
import {Button} from "@nextui-org/react";
import {PencilSquareIcon} from "@heroicons/react/24/outline";
import PageHeaderLayout from "../../components/PageHeaderLayout";

function TicketCreatePage() {

    const navigate = useNavigate()
    const {data, loading, errors, fetchData, success} = useApi()
    const [formData, setFormData] = useState({})

    useEffect(() => {
        if (success) {
            navigate(`/ticket/${data?.id}`)
        }
    }, [success]);

    const onSubmit = () => {
        fetchData("/ticket", "POST", formData, validationSchemas.createTicket)
    }

    return(
        <PageLayout className="form-wrapper">
            <PageHeaderLayout
                title={rendition("New ticket")}
            />

            <TicketForm
                formData={formData}
                setFormData={setFormData}
                errors={errors}
            />


            <TelegramButton
                text={rendition("Create new ticket")}
                onClick={onSubmit}
                disabled={loading}/>
        </PageLayout>
    )
}

export default TicketCreatePage
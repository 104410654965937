import ListBoxComponent from "../../components/ListBoxComponent";
import PageIndexLayout from "../../components/PageIndexLayout";
import {useNavigate, useSearchParams} from "react-router-dom";
import PageLayout from "../../components/PageLayout";
import React from "react";
import {rendition} from "../../services/i18n";
import PageHeaderLayout from "../../components/PageHeaderLayout";

function ParticipantIndexPage() {

    const navigate = useNavigate()
    const [queryParameters] = useSearchParams()
    let bot_id = queryParameters.get("bot_id")


    const mapData = (data) => {
        return data?.map((i) => ({
            id: i.id,
            title: i.title,
            description: i.title,
            avatar: null,
        }))
    }

    return(
        <PageLayout className="page-wrapper">
            <PageHeaderLayout
                title={rendition("Form replies")}
                subtitle={rendition("List of answers")}
            />

            <PageIndexLayout method={`/participant?bot_id=${bot_id}`}>
                {({data}) => (
                    <ListBoxComponent
                        onAction={(i, index) => navigate(`/participant/${i.id}`)}
                        data={mapData(data)} />
                )}
            </PageIndexLayout>
        </PageLayout>
    )
}

export default ParticipantIndexPage
import {useEffect, useState} from "react";
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import useApi from "../../services/useApi";
import ListBox2Component from "../../components/ListBox2Component";
import {rendition} from "../../services/i18n";
import {Avatar, Button, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger, User} from "@nextui-org/react";
import {IconWrapper} from "../../components/icon/IconWrapper";
import {
    UsersIcon,
    RectangleStackIcon,
    PaintBrushIcon,
    ChatBubbleBottomCenterTextIcon,
    DocumentTextIcon,
    ViewfinderCircleIcon,
    AcademicCapIcon,
    Squares2X2Icon,
    MegaphoneIcon,
    DevicePhoneMobileIcon, Square3Stack3DIcon, HomeIcon, StarIcon, Cog6ToothIcon, KeyIcon, TrashIcon, MoonIcon
} from "@heroicons/react/24/outline"
import PageLayout from "../../components/PageLayout";
import DarkModeToggle from "../../components/DarkModeToggle";
import {ItemCounter} from "../../components/icon/ItemCounter";
import {useBot} from "../../providers/BotProvider";
import {SparklesIcon} from "@heroicons/react/24/outline";
import FastModal from "../../components/FastModal";
import {CheckBadgeIcon} from "@heroicons/react/24/solid";




function SettingMenu({id, bot}) {
    const navigate = useNavigate()

    return (
        <Dropdown>
            <DropdownTrigger>
                <Button isIconOnly variant="flat">
                    <Cog6ToothIcon className="h-6 w-6" />
                </Button>
            </DropdownTrigger>
            <DropdownMenu aria-label="Static Actions">
                <DropdownItem
                    isReadOnly
                    key="profile"
                    className="h-14 gap-2 opacity-100"
                >
                    <User
                        name={bot.first_name}
                        description={`@${bot.username}`}
                        classNames={{
                            name: "text-default-600",
                            description: "text-default-500",
                        }}
                        avatarProps={{
                            size: "sm",
                            src: bot.profile,
                        }}
                    />
                </DropdownItem>

                <DropdownItem
                    closeOnSelect={false}
                    isReadOnly
                    startContent={<MoonIcon className="w-6 h-6" />}
                    endContent={<DarkModeToggle type="switch" />}
                    key="dark">
                    {rendition("Dark Mode")}
                </DropdownItem>
                <DropdownItem
                    showDivider
                    onClick={() => navigate(`/bot/${id}`)}
                    startContent={<KeyIcon className="w-6 h-6" />}
                    endContent={<ItemCounter />}
                    key="edit">
                    {rendition("Edit Token")}
                </DropdownItem>
                <DropdownItem
                    onClick={() => navigate(`/bot/${id}`)}
                    startContent={<TrashIcon className="w-6 h-6" />}
                    key="delete"
                    className="text-danger"
                    color="danger">
                    {rendition("Delete this bot")}
                </DropdownItem>
            </DropdownMenu>
        </Dropdown>
    )
}


function HeaderSection({id, bot}) {
    const navigate = useNavigate()
    const [hasPremium, setHasPremium] = useState(false)

    return (
        <div className="flex justify-between p-2">
            <div className="flex gap-4">


                {bot.plane === "free" && <Button
                    onClick={() => navigate(`/premium?bot_id=${id}`)}
                    className="text-white bg-gradient-to-r from-cyan-500 to-blue-500"
                    size="sm"
                    variant="flat"
                    radius="full"
                    startContent={<StarIcon className="w-5 h-5"/>}>
                    {rendition('Premium')}
                </Button>}

                {bot.plane !== "free" && (
                    <>
                    { hasPremium && <FastModal
                            icon={<SparklesIcon className="text-red-600 w-16 h-16" /> }
                            title={rendition('Premium Plan')}
                            onAction={() => setHasPremium(false)}
                            setClosed={() => setHasPremium(false)}
                            onActionTitle={rendition('OK')}
                            body={rendition('This telegram bot upgraded to Premium plan')} /> }

                        <Button
                            onClick={() => setHasPremium(true)}
                            className="text-white bg-gradient-to-r from-rose-500 to-red-500"
                            size="sm"
                            variant="flat"
                            radius="full"
                            startContent={<CheckBadgeIcon className="w-5 h-5"/>}>
                            {rendition('Premium')}
                        </Button>
                    </>
                )}

                <Link to="/ticket" className="flex">
                    <IconWrapper className="w-14 h-14">
                        <ChatBubbleBottomCenterTextIcon className="w-5 h-5" />
                    </IconWrapper>
                    {rendition('Support')}
                </Link>
            </div>
            <div className="flex gap-4">
                <SettingMenu id={id} bot={bot}/>
            </div>
        </div>
    )
}


function ProfileSection({id, bot}) {
    return (
        <>
            <Avatar className="w-24 h-24 text-large mx-auto" src={bot.profile} />

            <div className="flex px-10 pt-6 justify-between text-center gap-2">
                <Link to={`/command?bot_id=${id}`} className="text-center mx-auto">
                    <span className="icon-wrapper">
                        <PaintBrushIcon className="w-6 h-6" />
                    </span>
                    <span className="text-sm font-bold">{rendition('Commands')}</span>
                </Link>
                <Link to={`/chat?bot_id=${id}`} className="text-center mx-auto">
                    <span className="icon-wrapper">
                        <UsersIcon className="w-6 h-6" />
                    </span>
                    <span className="text-sm font-bold">{rendition('Subscribes')}</span>
                </Link>
                <Link to={`/participant?bot_id=${id}`} className="text-center mx-auto">
                    <span className="icon-wrapper">
                        <RectangleStackIcon className="w-6 h-6" />
                    </span>
                    <span className="text-sm font-bold">{rendition('Form replies')}</span>
                </Link>
            </div>
        </>

    )
}






function BotDashboardPage() {

    const navigate = useNavigate()
    const apiGet = useApi()
    const [queryParameters] = useSearchParams()
    let id = queryParameters.get("bot_id")
    const {myBot} = useBot()
    useEffect(() => {
        apiGet.fetchData(`/bot/${id}`)
    }, []);


    const typeOptions = [
        {
            id: "command",
            title: rendition("Create menu"),
            icon: <Squares2X2Icon className="w-6 h-6" />,
        },
        {
            id: "inline",
            title: rendition("Create inline keyboard"),
            icon: <ViewfinderCircleIcon className="w-6 h-6" />,
        },
        {
            id: "form",
            title: rendition("Create form"),
            icon: <DocumentTextIcon className="w-6 h-6" />,
        },
        {
            id: "quiz",
            title: rendition("Create quiz"),
            icon: <AcademicCapIcon className="w-6 h-6" />,
        }
    ]

    const items = [
        {
            id: `/bulk-message/create?bot_id=${id}`,
            title: rendition("Send post"),
            icon: <MegaphoneIcon className="w-6 h-6" />,
        },
        {
            id: `/bot/${id}/setting?step=start`,
            title: rendition("Edit start message"),
            icon: <HomeIcon className="w-6 h-6" />,
        },
        {
            id: `/bot/${id}/setting?step=forceJoin`,
            title: rendition("Set force join"),
            icon: <MegaphoneIcon className="w-6 h-6" />,
        },
        {
            id: `/bot/${id}/setting?step=contactRequest`,
            title: rendition("Share contact setting"),
            icon: <DevicePhoneMobileIcon className="w-6 h-6" />,
        },
        {
            id: `/bot/${id}/setting?step=replyMarkupLayout`,
            title: rendition("Edit menu layout"),
            icon: <Square3Stack3DIcon className="w-6 h-6" />,
        }
    ]

    return(
        <PageLayout className="page-wrapper">

            <HeaderSection id={id} bot={myBot} />

            <ProfileSection id={id} bot={myBot} />

            <div className="pt-8 space-y-8">
                <ListBox2Component
                    title={rendition("Create new command")}
                    data={typeOptions}
                    onAction={(i) => navigate(`/command/create?bot_id=${id}&type=${i.id}`)} />

                <ListBox2Component
                    title={rendition("Settings")}
                    data={items}
                    onAction={(i) => navigate(i.id)} />
            </div>

        </PageLayout>
    )
}

export default BotDashboardPage
import Input from "../../components/ui/Input";
import {Checkbox, CheckboxGroup, Textarea} from "@nextui-org/react";
import {Tabs, Tab} from "@nextui-org/react";
import Select from "../../components/ui/Select";
import {RadioGroup, Radio} from "@nextui-org/react";
import {rendition} from "../../services/i18n";


export const QuizForm = ({modalData, setModalData, errors, onChangeHandler}) => {

    const handleTagInput = (e, index) => {
        const newTags = [...modalData.options];
        newTags[index] = e.target.value;
        setModalData({ ...modalData, options: newTags });
    }

    const handleAddTag = () => {
        if ( modalData.options.length < 10 && (modalData.options.length === 0 || modalData.options[modalData.options.length - 1] !== "") ) {
            setModalData({ ...modalData, options: [...modalData.options, ""] });
        }
    }

    return (
        <>
            <Textarea
                name="message"
                label={rendition("Question")}
                value={modalData.message}
                onChange={(e) => onChangeHandler(e)}
                errorMessage={errors.message} />


            <RadioGroup name="correct_option"  defaultValue={modalData.correct_option} label={rendition("Options")}>
                {modalData.options && modalData.options.map((tag, index) => (
                    <div key={index} className="flex">
                        <Radio
                            value={index}
                            onChange={(e) => setModalData({...modalData, correct_option: parseInt(e.target.value)})} />

                        <Input
                            errorMessage={errors.options}
                            value={tag}
                            onKeyUp={handleAddTag}
                            onChange={(e) => handleTagInput(e, index)} />
                    </div>
                ))}
            </RadioGroup>


        </>
    )
}


export const QuestionsForm = ({modalData, options, errors, onChangeHandler}) => {

    return (
        <>
            <Input
                name="title"
                label={rendition("Title")}
                value={modalData.title}
                onChange={(e) => onChangeHandler(e)}
                errorMessage={errors.title} />
            <Textarea
                name="message"
                label={rendition("Question")}
                value={modalData.message}
                onChange={(e) => onChangeHandler(e)}
                errorMessage={errors.message} />
            <Select
                items={options.rules ?? []}
                name="rules"
                label={rendition("Select validation type")}
                value={modalData.rules}
                selectedKeys={[modalData.rules?.toString()]}
                onChange={(e) => onChangeHandler(e)}
                errorMessage={errors.rules} />
            <Checkbox
                size="lg"
                name="required"
                value={modalData.required}
                onChange={(e) => onChangeHandler(e)}
                errorMessage={errors.required} >{rendition("Answer is required")}</Checkbox>
        </>
    )
}


export const InlineKeyboardForm = ({modalData, setModalData, errors, onChangeHandler}) => {
    return (
        <>
            <Tabs className="mx-auto" onSelectionChange={(key) => setModalData({...modalData, type: key})}>
                <Tab className="flex flex-col gap-3" key="url" title="Link" >
                    <Input
                        name="title"
                        label={rendition("Title")}
                        value={modalData.title}
                        onChange={(e) => onChangeHandler(e)}
                        errorMessage={errors.title} />
                    <Input
                        name="url"
                        label={rendition("URL")}
                        description={rendition("For e.x https://google.com or https://t.me/username")}
                        value={modalData.url}
                        onChange={(e) => onChangeHandler(e)}
                        errorMessage={errors.url} />
                </Tab>
                <Tab className="flex flex-col gap-3" key="message" title="Message">
                    <Input
                        name="title"
                        label={rendition("Title")}
                        value={modalData.title}
                        onChange={(e) => onChangeHandler(e)}
                        errorMessage={errors.title} />
                    <Textarea
                        name="message"
                        label={rendition("Message")}
                        value={modalData.message}
                        onChange={(e) => onChangeHandler(e)}
                        errorMessage={errors.message} />
                </Tab>
            </Tabs>
        </>
    );
}
import {Button, Switch} from "@nextui-org/react";
import useDarkMode from "use-dark-mode";
import {SunIcon, MoonIcon} from "@heroicons/react/24/outline"
import {useEffect, useState} from "react";

function DarkModeToggle({type="button"}) {

    const darkMode = useDarkMode()
    const [dark, setDark] = useState(darkMode.value === "dark")

    const toggle = () => {
        darkMode.toggle()
        document.documentElement.className = darkMode.value ? 'dark' : 'light';
    }


    return (
        <>
        { type === "switch" && <Switch
            isSelected={darkMode.value}
            onValueChange={(value) => toggle()}
            size="sm" /> }

        {type === "button" && <Button isIconOnly variant="light" size="sm" onPress={toggle}>
            {darkMode.value ? <MoonIcon className="w-6 h-6"/> : <SunIcon className="w-6 h-6"/>}
        </Button>}
        </>
    )
}


export default DarkModeToggle;
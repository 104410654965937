import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import ChatComponent from "../../components/ChatComponent";
import PageLayout from "../../components/PageLayout";
import useApi from "../../services/useApi";


const TicketShowPage = () => {

    const {id} = useParams()
    const [messages, setMessages] = useState([])
    const [input, setInput] = useState("")
    const {data, fetchData} = useApi()
    const replyApi = useApi()

    const mappedData = (data) => {
        const {ticket_messages} = data
        return ticket_messages?.map(i => ({
            role: i.is_bot ? "agent" : "user",
            content: i.message,
        }))
    }

    useEffect(() => {
        fetchData(`/ticket/${id}`)
    }, []);

    useEffect(() => {
        if (data && data.ticket_messages) {
            setMessages(mappedData(data))
        }
    }, [data]);

    const onSubmit = (event) => {
        event.preventDefault()
        if (input.trim().length === 0) return

        replyApi.fetchData(`/ticket/${id}`, "POST", {
            _method: "put",
            message: input.trim(),
            media: ""
        })

        setMessages([
            ...messages,
            {
                role: "user",
                content: input,
            },
        ])
        setInput("")
    }

    return (
        <PageLayout loading={!data}>
            <ChatComponent
                title={data?.title}
                subtitle={data?.status}
                onSubmit={onSubmit}
                input={input}
                setInput={setInput}
                messages={messages}
                setMessages={setMessages} />
        </PageLayout>
    );
};

export default TicketShowPage;
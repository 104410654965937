import React, {useEffect, useState} from "react";
import {rendition} from "../../services/i18n"
import {useNavigate, useParams} from 'react-router-dom';
import useApi from "../../services/useApi";
import validationSchemas from "../../schema/validationSchemas";
import TelegramButton from "../../components/ui/TelegramButton";
import PageLayout from "../../components/PageLayout";
import { CheckIcon } from '@heroicons/react/24/solid'
import BotForm from "./BotForm";
import PageHeaderLayout from "../../components/PageHeaderLayout";
import {useBot} from "../../providers/BotProvider";

function BotCreatePage() {

    const { id } = useParams()
    const navigate = useNavigate()
    const apiGet = useApi()
    const apiPost = useApi()
    const {setBotMounted} = useBot()

    const [formData, setFormData] = useState({})
    const [method, setMethod] = useState("create")



    useEffect(() => {
        if (typeof id === 'string' && Number.isInteger(parseInt(id))){
            setMethod("edit")
            apiGet.fetchData(`/bot/${id}`)
        }
    }, [id]);

    useEffect(() => {
        if (apiGet.data) {
            const {token} = apiGet.data
            const updatedData = { token, _method: 'put' }
            setFormData(updatedData)
        }
    }, [apiGet.data]);

    const onSubmit = () => {
        let url = method === "create"? `/bot` : `/bot/${id}`
        apiPost.fetchData(url, "POST", formData, validationSchemas.createBot)
    }

    useEffect(() => {
        apiPost.success && setBotMounted(false)
    }, [apiPost.success]);

    return(
        <PageLayout className="form-wrapper">
            { (apiPost.success && method === "create") ? (
                <>
                    <div className="flex-col space-y-4 mt-5 text-center">
                        <span>
                            <CheckIcon className="w-16 h-16 mx-auto text-green-500" />
                        </span>
                        <p className="text-center">
                            {rendition("Your bot is ready now!")}
                        </p>
                    </div>

                    <TelegramButton
                        text={rendition("Go to dashboard")}
                        onClick={() => navigate(`/bot/dashboard?bot_id=${apiPost.data?.id}`)} />
                </>
            ) : (
                <>
                    <PageHeaderLayout
                        title={rendition("New bot")}
                    />

                    { method === "create" && <ul className="flex-col space-y-2">
                        <li className="p-3 rounded-md bg-default text-default-foreground">
                            <span
                                className="px-2 rounded-md mr-2 bg-gradient-to-br from-indigo-500 to-pink-500 border-small border-white/50 shadow-pink-500/30">1</span>
                            {rendition("Go to @BotFather. Press his name to do that and then press 'Start' if it's needed.")}
                        </li>
                        <li className="p-3 rounded-md bg-default text-default-foreground">
                            <span
                                className="px-2 rounded-md mr-2 bg-gradient-to-br from-indigo-500 to-pink-500 border-small border-white/50 shadow-pink-500/30">2</span>
                            {rendition("Create a new bot with him. To do this use the 'newbot' command inside @BotFather.")}
                        </li>
                        <li className="p-3 rounded-md bg-default text-default-foreground">
                            <span
                                className="px-2 rounded-md mr-2 bg-gradient-to-br from-indigo-500 to-pink-500 border-small border-white/50 shadow-pink-500/30">3</span>
                            {rendition("Copy the API token that @BotFather will give you.")}
                        </li>
                        <li className="p-3 rounded-md bg-default text-default-foreground">
                            <span
                                className="px-2 rounded-md mr-2 bg-gradient-to-br from-indigo-500 to-pink-500 border-small border-white/50 shadow-pink-500/30">4</span>
                            {rendition("Come back to here and paste the copied API token here. 👇👇")}
                        </li>
                    </ul>}

                    <BotForm
                        formData={formData}
                        setFormData={setFormData}
                        errors={apiPost.errors} />

                    <TelegramButton
                        text={method === "create" ? rendition("Create new bot") : rendition("Update")}
                        onClick={onSubmit}
                        disabled={apiPost.loading}/>
                </>
                )}
        </PageLayout>
    )
}

export default BotCreatePage
import React, {useEffect, useRef} from 'react';
import {Avatar, Button, cn, Input} from "@nextui-org/react";
import {PaperAriPlaneIcon} from "./icon/PaperAriPlaneIcon";
import {rendition} from "../services/i18n";

const ChatComponent = ({title, subtitle, messages, input, setInput, onSubmit, avatar = null}) => {

    const divRef = useRef(null);
    const inputRef = useRef(null);
    const inputLength = input.trim().length

    useEffect(() => {
        scrollToBottom()
    }, [messages]);

    const scrollToBottom = () => {
        if (divRef.current) {
            divRef.current.scrollIntoView({
                behavior: 'smooth',
            });

            if (inputRef.current)
                inputRef.current.focus()
        }
    };

    const submitHandler = (e) => {
        onSubmit(e)
        scrollToBottom()
    }

    return (
        <div className="flex flex-col">

            <div className="bg-white p-6 dark:bg-gray-800 flex flex-row items-center">
                <div className="flex items-center space-x-4">
                    { avatar && <Avatar src={avatar} />}
                    <div>
                        <p className="text-sm font-medium leading-none">{title}</p>
                        <p className="text-sm text-muted-foreground">{subtitle}</p>
                    </div>
                </div>
            </div>

            <div className="h-[500px] overflow-y-scroll  px-6 py-2">
                <div className="space-y-4">
                    {messages.map((message, index) => (
                        <div
                            key={index}
                            className={cn(
                                "flex w-max max-w-[75%] flex-col gap-2 rounded-lg px-3 py-2 text-sm",
                                message.role === "user"
                                    ? "ml-auto bg-primary text-primary-foreground"
                                    : "bg-muted"
                            )}
                        >
                            {message.content}
                        </div>
                    ))}
                    <span className="h-8 block" ref={divRef} />
                </div>
            </div>

            <div className="px-6 py-4 bg-white dark:bg-gray-800">
                <form
                    onSubmit={(e) => submitHandler(e)}
                    className="flex w-full items-center space-x-2">
                    <Input
                        ref={inputRef}
                        autoFocus
                        id="message"
                        placeholder={rendition("Type your message...")}
                        className="flex-1"
                        autoComplete="off"
                        size="sm"
                        value={input}
                        onChange={(event) => setInput(event.target.value)}
                    />
                    <Button color="primary" type="submit" isIconOnly  disabled={inputLength === 0}>
                        <PaperAriPlaneIcon className="h-4 w-4" />
                        <span className="sr-only">Send</span>
                    </Button>
                </form>
            </div>
        </div>
    );
};

export default ChatComponent;

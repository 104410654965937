import {useEffect} from "react";

const TelegramExpand = () => {

    const telegram = process.env.REACT_APP_TELEGRAM_PWA

    useEffect(() => {
        if(telegram && typeof window !== "undefined" && typeof window.Telegram !== "undefined") {
            window.Telegram.WebApp.expand()
        }
    }, []);
};

export default TelegramExpand;
import Input from "../../components/ui/Input";
import {rendition} from "../../services/i18n";
import Textarea from "../../components/ui/Textarea";
import ToggleBox from "../../components/ToggleBox";
import {useSearchParams} from "react-router-dom";
import {RadioGroup} from "@nextui-org/react";
import CustomRadio from "../../components/ui/CustomRadio";
import {AtSymbolIcon, BellAlertIcon} from "@heroicons/react/24/outline";


function StartForm({formData, setFormData, errors}) {
    return (
        <>
            <Textarea
                label={rendition("Start message")}
                value={formData.start_message}
                onValueChange={(v) => setFormData(prev => ({...prev, start_message: v}))}
                errorMessage={errors.start_message}/>
        </>
    )
}



function ForceJoinForm({formData, setFormData, errors}) {

    const handleTagInput = (e, index) => {
        const newTags = [...formData.channels];
        newTags[index] = e.target.value;
        setFormData({ ...formData, channels: newTags });
    }

    const handleAddTag = () => {
        const channels = formData.channels;
        const filteredChannels = channels.filter((channel, index) => index === channels.length - 1 || channel !== "");
        if (filteredChannels.length < 6 && (filteredChannels.length === 0 || filteredChannels[filteredChannels.length - 1] !== "")) {
            setFormData({ ...formData, channels: [...filteredChannels, ""] });
        }else{
            setFormData({ ...formData, channels: [...filteredChannels] });
        }
    };

    return (
        <>
            <ToggleBox
                label={rendition("Join to channel first")}
                hint={rendition("Force user to join in your telegram channels to use this bot")}
                isSelected={formData.force_join}
                setIsSelected={(v) => setFormData(prev => ({...prev, force_join: v}))} />

            { formData.force_join && (
                <>
                    <Textarea
                        label={rendition("Message")}
                        placeholder={rendition("for use this bot please first join to this channels")}
                        value={formData.force_join_message}
                        onValueChange={(v) => setFormData(prev => ({...prev, force_join_message: v}))}
                        errorMessage={errors.force_join_message} />

                    <h2 className="font-bold pt-6">{rendition("Enter channels username")}</h2>
                    <span className="">{rendition("Enter channels(s) username in format @channel (only public channel supported now)")}</span>
                    <div className="flex gap-2 bg-red-600 text-white p-2 rounded-md">
                        <BellAlertIcon className="w-8 h-8" />
                        {rendition("You must add This bot to all below channel as admin permission to be effect.")}
                    </div>

                    {formData.channels && formData.channels.map((tag, index) => (
                        <div key={index} className="flex">
                            <Input
                                startContent={<AtSymbolIcon className="w-5 h-5"/>}
                                errorMessage={errors.channels}
                                value={tag}
                                onKeyDown={handleAddTag}
                                onChange={(e) => handleTagInput(e, index)}
                            />
                        </div>
                    ))}
                </>
            )}
        </>
    )
}


function ContactRequestForm({formData, setFormData, errors}) {
    return (
        <>
            <ToggleBox
                label={rendition("Contact request")}
                hint={rendition("For use bot's menu user must send mobile number (telegram mobile number) in bot.")}
                isSelected={formData.contact_request}
                setIsSelected={(v) => setFormData(prev => ({...prev, contact_request: v}))} />

            { formData.contact_request && (
                <>
                    <Textarea
                        label={rendition("Share contact message")}
                        value={formData.contact_request_message}
                        onValueChange={(v) => setFormData(prev => ({...prev, contact_request_message: v}))}
                        errorMessage={errors.contact_request_message} />
                </>
            )}
        </>
    )
}


function ReplyMarkupLayoutForm({formData, setFormData, errors}) {

    return (
        <>
            <RadioGroup
                value={formData.reply_markup_layout}
                onValueChange={(v) => setFormData(prev => ({...prev, reply_markup_layout: v}))}
                label={rendition("Select a layout for bot's Main menu and Submenus")}
            >
                <div className="grid grid-cols-3 gap-1">
                    <CustomRadio value={232}>
                        <div className="w-14">
                            <div className="flex gap-1 my-1">
                                <span className="bg-gray-200 w-full h-3"></span>
                                <span className="bg-gray-200 w-full h-3"></span>
                            </div>
                            <div className="flex gap-1 my-1">
                                <span className="bg-gray-200 w-full h-3"></span>
                                <span className="bg-gray-200 w-full h-3"></span>
                                <span className="bg-gray-200 w-full h-3"></span>
                            </div>
                            <div className="flex gap-1 my-1">
                                <span className="bg-gray-200 w-full h-3"></span>
                                <span className="bg-gray-200 w-full h-3"></span>
                            </div>
                        </div>
                    </CustomRadio>

                    <CustomRadio value={121}>
                        <div className="w-14">
                            <div className="flex gap-1 my-1">
                                <span className="bg-gray-200 w-full h-3"></span>
                            </div>
                            <div className="flex gap-1 my-1">
                                <span className="bg-gray-200 w-full h-3"></span>
                                <span className="bg-gray-200 w-full h-3"></span>
                            </div>
                            <div className="flex gap-1 my-1">
                                <span className="bg-gray-200 w-full h-3"></span>
                            </div>
                        </div>
                    </CustomRadio>

                    <CustomRadio value={111}>
                        <div className="w-14">
                            <div className="flex gap-1 my-1">
                                <span className="bg-gray-200 w-full h-3"></span>
                            </div>
                            <div className="flex gap-1 my-1">
                                <span className="bg-gray-200 w-full h-3"></span>
                            </div>
                            <div className="flex gap-1 my-1">
                                <span className="bg-gray-200 w-full h-3"></span>
                            </div>
                        </div>
                    </CustomRadio>

                    <CustomRadio value={222}>
                        <div className="w-14">
                            <div className="flex gap-1 my-1">
                                <span className="bg-gray-200 w-full h-3"></span>
                                <span className="bg-gray-200 w-full h-3"></span>
                            </div>
                            <div className="flex gap-1 my-1">
                                <span className="bg-gray-200 w-full h-3"></span>
                                <span className="bg-gray-200 w-full h-3"></span>
                            </div>
                            <div className="flex gap-1 my-1">
                                <span className="bg-gray-200 w-full h-3"></span>
                                <span className="bg-gray-200 w-full h-3"></span>
                            </div>
                        </div>
                    </CustomRadio>

                    <CustomRadio value={333}>
                        <div className="w-14">
                            <div className="flex gap-1 my-1">
                                <span className="bg-gray-200 w-full h-3"></span>
                                <span className="bg-gray-200 w-full h-3"></span>
                                <span className="bg-gray-200 w-full h-3"></span>
                            </div>
                            <div className="flex gap-1 my-1">
                                <span className="bg-gray-200 w-full h-3"></span>
                                <span className="bg-gray-200 w-full h-3"></span>
                                <span className="bg-gray-200 w-full h-3"></span>
                            </div>
                            <div className="flex gap-1 my-1">
                                <span className="bg-gray-200 w-full h-3"></span>
                                <span className="bg-gray-200 w-full h-3"></span>
                                <span className="bg-gray-200 w-full h-3"></span>
                            </div>
                        </div>
                    </CustomRadio>
                </div>
            </RadioGroup>
        </>
    )
}



function BotSettingForm({formData, setFormData, errors}) {

    const [queryParameters] = useSearchParams()
    let step = queryParameters.get("step")


    return(
        <>
            { step === "start" &&
                <StartForm
                    formData={formData}
                    setFormData={setFormData}
                    errors={errors} /> }

            { step === "forceJoin" &&
                <ForceJoinForm
                    formData={formData}
                    setFormData={setFormData}
                    errors={errors} />}


            { step === "contactRequest" &&
                <ContactRequestForm
                    formData={formData}
                    setFormData={setFormData}
                    errors={errors} /> }


            { step === "replyMarkupLayout" &&
                <ReplyMarkupLayoutForm
                    formData={formData}
                    setFormData={setFormData}
                    errors={errors} /> }
        </>
    )
}

export default BotSettingForm
import Input from "../../components/ui/Input";
import {Radio, RadioGroup, Textarea} from "@nextui-org/react";
import Select from "../../components/ui/Select";
import {rendition} from "../../services/i18n";
import ListBox2Component from "../../components/ListBox2Component";
import {ChatIcon} from "../../components/icon/ChatIcon";


const DataForm = ({formData, setFormData, errors, options}) => {

    const onChangeHandler = (e) => {
        setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }));
    }

    const typeOptions = [
        {
            id: "command",
            title: rendition("Menu"),
            icon: <ChatIcon className="text-lg" />,
        },
        {
            id: "inline",
            title: rendition("Inline keyboard"),
            icon: <ChatIcon className="text-lg" />,
        },
        {
            id: "form",
            title: rendition("Form"),
            icon: <ChatIcon className="text-lg" />,
        },
        {
            id: "quiz",
            title: rendition("Quiz"),
            icon: <ChatIcon className="text-lg" />,
        }
    ]

    return (
        <>
            { !formData.type && <ListBox2Component
                data={typeOptions}
                onAction={(i) =>  setFormData(prev => ({ ...prev, type: i.id })) } />}

            { formData.type &&
                <>
                    <Input
                        name="title"
                        label={rendition("Title")}
                        value={formData.title}
                        onChange={(e) => onChangeHandler(e)}
                        errorMessage={errors.title} />

                    <Textarea
                        name="message"
                        label={rendition("Response message")}
                        value={formData.message}
                        onChange={(e) => onChangeHandler(e)}
                        errorMessage={errors.message} />

                    <Textarea
                        name="message_end"
                        label={rendition("Result message")}
                        value={formData.message_end}
                        onChange={(e) => onChangeHandler(e)}
                        errorMessage={errors.message_end} />

                    <Select
                        name="parent_id"
                        label={rendition("Parent menu")}
                        items={options.parents ?? []}
                        value={formData.parent_id}
                        selectedKeys={[formData.parent_id?.toString()]}
                        onChange={(e) => onChangeHandler(e)}
                        errorMessage={errors.parent_id} />

                    <Select
                        name="media_id"
                        label={rendition("Select media")}
                        description={rendition("Upload or forward files to your telegram bot to be selectable here.")}
                        items={options.media ?? []}
                        value={formData.media_id}
                        selectedKeys={[formData.media_id?.toString]}
                        onChange={(e) => onChangeHandler(e)}
                        errorMessage={errors.media_id} />
                </>
            }
        </>
    );
}



export default DataForm;
import {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import PageLayout from "../../components/PageLayout";
import useApi from "../../services/useApi";
import PageHeaderLayout from "../../components/PageHeaderLayout";
import {CheckCircleIcon, XCircleIcon} from "@heroicons/react/24/solid";


const ParticipantShowPage = () => {

    const {id} = useParams()
    const {data, loading, fetchData} = useApi()
    const [user, setUser] = useState({})

    useEffect(() => {
        fetchData(`/participant/${id}`)
    }, []);

    useEffect(() => {
        if (data){
            setUser({
                name: data.chat.first_name + ' ' + data.chat.last_name,
                username:  data.chat.username? '- @' + data.chat.username : null,
            })
        }
    }, [data]);


    return (
        <PageLayout loading={loading}>
            <PageHeaderLayout
                title={data?.title}
                subtitle={`${user?.name} ${user?.username}`} />

            <dl className="divide-y divide-gray-100">
                { data && data.type === "form" && data?.content.map(({title, value}) => (
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm">{title}</dt>
                        <dd className="font-medium">{value}</dd>
                    </div>
                )) }
            </dl>

            <dl className="divide-y divide-gray-100">
                { data && data.type === "quiz" && data?.content.map(({message, options, selected_option, correct_option}) => (
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm">{message}</dt>
                        <dd className="font-medium">
                            <ul>
                                {options.map((i, index) => (<li>
                                    { index === parseInt(selected_option) && (
                                        (parseInt(correct_option) === parseInt(selected_option))
                                        ? <CheckCircleIcon className="w-6 h-6 inline-block text-green-500" />
                                        : <XCircleIcon className="w-6 h-6 inline-block text-red-500" /> )} {i}
                                </li>))}
                            </ul>
                        </dd>
                    </div>
                )) }
            </dl>
        </PageLayout>
    )
}

export default ParticipantShowPage;
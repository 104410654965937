import React, {useEffect, useState} from 'react';
import PageLayout from "../../components/PageLayout";
import {rendition} from "../../services/i18n";
import TelegramButton from "../../components/ui/TelegramButton";
import BulkMessageForm from "./BulkMessageForm";
import useApi from "../../services/useApi";
import validationSchemas from "../../schema/validationSchemas";
import {useSearchParams} from "react-router-dom";
import PageHeaderLayout from "../../components/PageHeaderLayout";

const BulkMessageCreatePage = () => {

    const [formData, setFormData] = useState({})
    const [options, setOptions] = useState({})

    const [queryParameters] = useSearchParams()
    let bot_id = queryParameters.get("bot_id")

    const {loading, errors, fetchData} = useApi()
    const apiSelect = useApi()

    useEffect(() => {
        apiSelect.fetchData("/command/options")
        setFormData(prev => ({ ...prev, bot_id: bot_id }))
    }, []);

    useEffect(() => {
        const {data} = apiSelect
        if(data) {
            setOptions({
                parents: data.parents,
                media: data.media,
                rules: data.rules,
            })
        }
    }, [apiSelect.data]);

    const onSubmit = () => {
        fetchData(`/bulk-message/${bot_id}`, "POST", formData, validationSchemas.createBulkMessage)
    }

    return (
        <PageLayout className="form-wrapper">
            <PageHeaderLayout
                title={rendition("Send new post")}
                subtitle={rendition("All bot subscribers will receive")} />

            <BulkMessageForm
                options={options}
                formData={formData}
                setFormData={setFormData}
                errors={errors} />

            <TelegramButton
                text={rendition("send")}
                onClick={onSubmit}
                disabled={loading}/>
        </PageLayout>
    )
}

export default BulkMessageCreatePage;
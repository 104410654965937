import Input from "../../components/ui/Input";
import Textarea from "../../components/ui/Textarea";
import {rendition} from "../../services/i18n";

function TicketForm({formData, setFormData, errors}) {
    return(
        <>
            <Input
                label={rendition("Subject")}
                value={formData.title}
                errorMessage={errors.title}
                onValueChange={(value) => setFormData((pre) => ({...pre, title: value}))}
            />
            <Textarea
                label={rendition("Message")}
                description={rendition("Describe your problem in as much detail as possible")}
                value={formData.message}
                errorMessage={errors.message}
                onValueChange={(value) => setFormData((pre) => ({...pre, message: value}))}
            />
        </>
    )
}

export default TicketForm
import useUpdate from "../../services/useUpdate";
import Modal from "../../components/ui/Modal";
import {QuestionsForm, InlineKeyboardForm, QuizForm} from "./ModalForms"
import {useEffect, useState} from "react";
import {Button} from "@nextui-org/react";
import validator from "../../services/validator";
import validationSchemas from "../../schema/validationSchemas";
import {rendition} from "../../services/i18n";



const ModalWrapper = ({modalData, setModalData, modalIndex, field, setField, options,
                          setModalIndex, formData, setFormData,
                          initTypes, isOpen, onClose, onOpenChange}) => {

    const [init, setInit] = useState()
    const [errors, setErrors] = useState({})
    const {push, update, remove} = useUpdate(setFormData, field)
    const sc = {
        quiz: validationSchemas.quizType,
        form: validationSchemas.formType,
        inline: validationSchemas.inlineType,
    }

    useEffect(() => {
        switch (formData.type) {
            case "quiz":
                setInit(initTypes.quizType)
                setModalData(initTypes.quizType)
                setField("questions")
                break;
            case "form":
                setInit(initTypes.formType)
                setModalData(initTypes.formType)
                setField("questions")
                break;
            default:
                setInit(initTypes.inlineType)
                setModalData(initTypes.inlineType)
                setField("inline_keyboards")
        }
    }, [formData.type])


    const onChangeHandler = (e) => {
        setModalData({...modalData, [e.target.name]: e.target.value})
    }

    const add = () => {
        push(modalData)
        initTypes === "inline"
            ? setModalData({...init, callback_data : 'call@' + (Math.random() + 1).toString(36).substring(7)})
            : setModalData(init)
        onClose()
    }

    const edit = (index) => {
        update(index, modalData)
        setModalData(init)
        onClose()
    }

    const del = (index) => {
        remove(index)
        setModalData(init)
        onClose()
    }

    const confirm = () => {
        setErrors({})
        const {errors} = validator(modalData, sc[formData.type])
        if ( Object.keys(errors).length !== 0 ) {
            setErrors(errors)
            console.log(errors)
        }else{
            if (modalIndex === null) add()
            else edit(modalIndex)
        }
    }

    return (
        <Modal
            confirm={confirm}
            isOpen={isOpen}
            onOpenChange={onOpenChange}>

            {formData.type === "quiz" &&
                <QuizForm
                    modalData={modalData}
                    setModalData={setModalData}
                    errors={errors}
                    onChangeHandler={onChangeHandler} />}

            {formData.type === "form" &&
                <QuestionsForm
                    options={options}
                    modalData={modalData}
                    setModalData={setModalData}
                    errors={errors}
                    onChangeHandler={onChangeHandler} />}

            {formData.type === "inline" &&
                <InlineKeyboardForm
                    modalData={modalData}
                    setModalData={setModalData}
                    errors={errors}
                    onChangeHandler={onChangeHandler} />}

            {modalIndex !== null &&
                <Button
                    onPress={() => del(modalIndex)}
                    color="danger"
                    variant="flat">
                    {rendition("Delete item")}
                </Button>}
        </Modal>
    );
}

export default ModalWrapper;
import { Outlet, Link } from "react-router-dom";
import {Providers} from "../Providers";
import AuthenticateLayout from "./AuthenticateLayout";
import {WebAppProvider} from "@vkruglikov/react-telegram-web-app";
import TelegramAuthenticateLayout from "./TelegramAuthenticateLayout";
import {useEffect} from "react";
import TelegramExpand from "../components/ui/TelegramExpand";
import TelegramReady from "../components/ui/TelegramReady";
import TelegramEnableClosingConfirmation from "../components/ui/TelegramEnableClosingConfirmation";

const telegram = process.env.REACT_APP_TELEGRAM_PWA


const ProvidersWrapper = ({children}) => {
    return (
        <Providers>
            {children}
        </Providers>
    )
}

const MyApp = () => {
    return (
        <>
            <Outlet />
        </>
    )
}

const TWrapper = () => {

    // useEffect(() => {
        // if(typeof window !== "undefined" && typeof window.Telegram !== "undefined") {
            // alert(window.Telegram.WebApp.platform)
            // window.Telegram.WebApp.enableClosingConfirmation()
            // window.Telegram.WebApp.expand()
            // window.Telegram.WebApp.ready()
        // }
    // }, []);


    return (
        <ProvidersWrapper>
            <WebAppProvider options={{smoothButtonsTransition: true}} >
                <TelegramAuthenticateLayout>
                    <TelegramExpand />
                    <TelegramEnableClosingConfirmation />
                    <TelegramReady />
                    <MyApp />
                </TelegramAuthenticateLayout>
            </WebAppProvider>
        </ProvidersWrapper>
    )
}

const Wrapper = () => {
    return (
        <ProvidersWrapper>
            <AuthenticateLayout>
                <MyApp />
            </AuthenticateLayout>
        </ProvidersWrapper>
    )
}

const Layout = () => {
    console.log("Telegram pwa", telegram)
    return (
        <>
            { (telegram && <TWrapper />) || <Wrapper /> }
        </>
    )
};

export default Layout;
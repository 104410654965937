import Textarea from "../../components/ui/Textarea";
import Select from "../../components/ui/Select";
import {rendition} from "../../services/i18n";

const BulkMessageForm = ({formData, setFormData, errors, options}) => {
    return (
        <>
            <Textarea
                label={rendition("Text")}
                value={formData.text}
                errorMessage={errors.text}
                onValueChange={(value) => setFormData((pre) => ({...pre, text: value}))} />

            <Select
                name="media_id"
                label={rendition("Select media")}
                description={rendition("Upload or forward files to your telegram bot to be selectable here.")}
                items={options.media ?? []}
                value={formData.media_id}
                selectedKeys={[formData.media_id?.toString]}
                onChange={(e) => setFormData((pre) => ({...pre, media_id: e.target.value}))}
                errorMessage={errors.media_id} />
        </>
    );
};

export default BulkMessageForm;
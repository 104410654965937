import ListBoxComponent from "../../components/ListBoxComponent";
import PageIndexLayout from "../../components/PageIndexLayout";
import {useNavigate, useSearchParams} from "react-router-dom";
import PageLayout from "../../components/PageLayout";
import React, {useState} from "react";
import {rendition} from "../../services/i18n";
import PageHeaderLayout from "../../components/PageHeaderLayout";
import LimitModal from "../payment/LimitModal";
import {useBot} from "../../providers/BotProvider";

function ChatIndexPage() {

    const [isPremium, setIsPremium] = useState(false)
    const {myBot} = useBot()
    
    const navigate = useNavigate()
    const [queryParameters] = useSearchParams()
    let bot_id = queryParameters.get("bot_id")

    const mapData = (data) => {
        return data?.map((i) => ({
            id: i.id,
            title: i.full_name,
            description: i.username,
            avatar: i.profile,
        }))
    }

    const onPremium = (id) => {
        myBot.plane === 'free' ? setIsPremium(true) : navigate(`/chat/${id}`)
    }

    return(
        <PageLayout className="page-wrapper">
            {isPremium && <LimitModal setIsPremium={setIsPremium} />}

            <PageHeaderLayout title={rendition("Subscribers")} />

            <PageIndexLayout method={`/chat?bot_id=${bot_id}`} >
                {({data}) => (
                    <ListBoxComponent
                        onAction={i => onPremium(i.id)}
                        data={mapData(data)} />
                )}
            </PageIndexLayout>
        </PageLayout>
    )
}

export default ChatIndexPage
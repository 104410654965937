// Example using React Context
import {createContext, useContext, useEffect, useMemo, useState} from 'react';
import useApi from "../services/useApi";
import {useAuth} from "./AuthProvider";
import {useSearchParams} from "react-router-dom";

const BotContext = createContext();

export const BotProvider = ({ children }) => {

    const {authenticated} = useAuth()
    const [botId, setBotId] = useState(null)
    const [botItems, setBotItems] = useState([])
    const [botMounted, setBotMounted] = useState(false)
    const {loading, data, fetchData, success} = useApi()
    const [queryParameters] = useSearchParams()
    let bot_id = queryParameters.get("bot_id")

    useEffect(() => {
        authenticated && !botMounted && fetchData("/bot")
    }, [authenticated, botMounted])

    useEffect(() => {
        if(data) {
            setBotMounted(true)
            setBotItems(data.data)
        }
    }, [data])


    const myBot = useMemo(() => {
        return botItems.find((row) => parseInt(row.id) === parseInt(bot_id))
    }, [bot_id]);

    const botType = {
        id: 1,
        first_name: 'ad',
        username: '@ad',
        profile: 'https://ple.json',
        setting: {

        },
        plane: 'free || premium',
        can: {
            create_menu: true,
            create_form: true,
            chat_history: true,
            bulk_message: true,
            contact_request: true,
            channels: 1
        },
    }
    return (
        <BotContext.Provider value={{
            botId,
            setBotId,
            myBot,
            botItems,
            setBotItems,
            loading,
            success,
            botMounted,
            setBotMounted }}>
            {children}
        </BotContext.Provider>
    );
};

export const useBot = () => useContext(BotContext);